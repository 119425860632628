var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout } from "~/core/decorator";
import { namespace } from "vuex-class";
var collectionManageModule = namespace("collection-manage");
var CollectionExecution = /** @class */ (function (_super) {
    __extends(CollectionExecution, _super);
    function CollectionExecution() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tableData = [
            {
                buinessid: '30968765',
                overdue: '2124.41',
                realrepay: '2148.98',
                time: '2023/5/1 3:00:58',
            }, {
                buinessid: '30580738',
                overdue: '4330.37',
                realrepay: '1719.18',
                time: '2023/5/1 23:33:38',
            }, {
                buinessid: '30494042',
                overdue: '316.91',
                realrepay: '309.63',
                time: '2023/5/1 21:13:33',
            }, {
                buinessid: '31339923',
                overdue: '1532.76',
                realrepay: '3104.56',
                time: '2023/5/3 21:59:20',
            }, {
                buinessid: '31994747',
                overdue: '385.54',
                realrepay: '277.92',
                time: '2023/5/3 21:47:28',
            }, {
                buinessid: '32168160',
                overdue: '178.7',
                realrepay: '8.77',
                time: '2023/5/3 22:04:15',
            }, {
                buinessid: '31596852',
                overdue: '56.21',
                realrepay: '784.1',
                time: '2023/5/4 20:51:27',
            }, {
                buinessid: '32752618',
                overdue: '232.31',
                realrepay: '9.92',
                time: '2023/5/4 21:52:12',
            }, {
                buinessid: '28712921',
                overdue: '4970.65',
                realrepay: '2423.24',
                time: '2023/5/4 23:03:19',
            }, {
                buinessid: '31768146',
                overdue: '916.24',
                realrepay: '34.56',
                time: '2023/5/5 20:42:08',
            }, {
                buinessid: '31418569',
                overdue: '3768.24',
                realrepay: '1860.67',
                time: '2023/5/5 21:35:41',
            }, {
                buinessid: '32573505',
                overdue: '394.79',
                realrepay: '389.61',
                time: '2023/5/5 21:26:21',
            }, {
                buinessid: '31425525',
                overdue: '6122.71',
                realrepay: '1194.44',
                time: '2023/5/6 12:20:58',
            }, {
                buinessid: '30870813',
                overdue: '2405.19',
                realrepay: '759.03',
                time: '2023/5/6 12:20:40',
            }, {
                buinessid: '32168160',
                overdue: '178.7',
                realrepay: '0.17',
                time: '2023/5/6 21:30:56',
            }, {
                buinessid: '31498503',
                overdue: '31.33',
                realrepay: '478.01',
                time: '2023/5/6 22:08:47',
            }, {
                buinessid: '31611121',
                overdue: '403.59',
                realrepay: '396.39',
                time: '2023/5/6 21:26:29',
            }, {
                buinessid: '33372412',
                overdue: '540.33',
                realrepay: '1649.29',
                time: '2023/5/7 23:15:19',
            }, {
                buinessid: '32352995',
                overdue: '392.76',
                realrepay: '17.86',
                time: '2023/5/7 22:07:57',
            }, {
                buinessid: '33372412',
                overdue: '540.33',
                realrepay: '1649.29',
                time: '2023/5/7 23:15:19',
            }, {
                buinessid: '32352995',
                overdue: '392.76',
                realrepay: '17.86',
                time: '2023/5/7 22:07:57',
            }, {
                buinessid: '30012090',
                overdue: '620.8',
                realrepay: '258.89',
                time: '2023/5/8 23:38:04',
            }, {
                buinessid: '32466781',
                overdue: '1888.49',
                realrepay: '502.73',
                time: '2023/5/9 16:22:09',
            }, {
                buinessid: '31734860',
                overdue: '17408.82',
                realrepay: '598.67',
                time: '2023-05-10 15:43:43',
            }, {
                buinessid: '30427507',
                overdue: '993.18',
                realrepay: '485.57',
                time: '2023-05-10 21:36:23',
            }, {
                buinessid: '32589761',
                overdue: '936.41',
                realrepay: '460.41',
                time: '2023-05-12 22:21:06',
            }, {
                buinessid: '32582037',
                overdue: '482.87',
                realrepay: '473.62',
                time: '2023-05-12 21:55:03',
            }, {
                buinessid: '32370669',
                overdue: '125.09',
                realrepay: '131.72',
                time: '2023-05-12 22:29:35',
            }, {
                buinessid: '32168160',
                overdue: '178.7',
                realrepay: '0.17',
                time: '2023-05-12 21:21:18',
            }
        ];
        return _this;
    }
    CollectionExecution = __decorate([
        Layout("default"),
        Component({
            components: {},
        })
    ], CollectionExecution);
    return CollectionExecution;
}(Vue));
export default CollectionExecution;
